import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SectionContent, SortedCards, Seo } from "../components";
import Layout from "../components/Layout";

const ArtistTemplate = ({ data, pageContext }) => {
  const books = data.artist.nodes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title={pageContext.artist} />
      <SortedCards books={books} title={pageContext.artist} />
    </Layout>
  );
};

export const query = graphql`
  query GetArtist($artist: String!) {
    artist: allAirtable(
      filter: { data: { artist: { eq: $artist }, status: { eq: "Available" } } }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default ArtistTemplate;
